/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #337dff;
  color: white !important;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: #007a3d;
  color: #00ad57;
  color: #ffb533;
  color: #ffac1a;
  color: #ffa300 !important;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  /*background: #00ad57;*/
    /*background: #47a607*/
    /*background: #337dff;*/
    background: #6b6bdf;
    /*background: black;*/
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  /*background: #57c5f7;*/
  /*background: #00ad57;*/
    background: #47a607
}

/* General sidebar styles */
.bm-menu {
  /*background: #2a3143;*/
  background: #f8f8ff;
  background: #0762a6;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.85) !important;
}

#sidebarSignature {
    color: white;
    margin-left: 10px;
    margin-top: -20px;
    user-select: none;
}

