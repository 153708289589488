@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600);
div.d3svg {
    overflow: hidden
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pumpkin {
    fill: yellow;
    stroke: orange;
    stroke-width: 5;
}

.greenStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #47a607;
    /*stroke: #337dff;*/
    stroke-width: 5
}

.purpleStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #763d7c;
    stroke-width: 5
}

.yellowStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #ffa300;
    stroke-width: 5
}

.blueStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #337dff;
    stroke-width: 5
}

.redStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #ff005c;
    stroke-width: 5
}

g.clickable circle.greenStar:hover {
    fill: red;
    stroke: #a60747;
    cursor: pointer;
}

g.clickable circle.redStar:hover {
    fill: red;
    stroke: #a60747;
    cursor: pointer;
}

g.clickable circle.blueStar:hover {
    fill: red;
    stroke: #a60747;
    cursor: pointer;
}

g.clickable circle.yellowStar:hover {
    fill: red;
    stroke: #a60747;
    cursor: pointer;
}

.settingSun {
    fill: red;
    stroke: purple;
    stroke-width: 10;
    transtion: fill 800ms
}

.greeting {
  font-family: sans-serif;
  margin-left: 25px;
  margin-right: 25px;
}

@media only screen and (min-width: 767px) {
.signature {
    margin-top: -5px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
}

@media only screen and (max-width: 767px) {
.signature {
    margin-top: -25px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
}



.name {
    margin-left: -10px;
}

.title {
    margin-top: -17px;
}


div.switch {
    margin-top: -10px;
}

@media only screen and (min-width: 767px) {
    .animation.toggle {
        margin-top: -15px;
        float: right;
    }
}

@media only screen and (max-width: 767px) {
    .animation.toggle {
        margin-top: -180px;
        float: right;
        display: none;
    }
}

@media only screen and (min-width: 767px) {
    #enterSite {
        float: left;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) {
    #enterSite {
        float: left;
        margin-top: -5px;
        margin-left: -18px;
        margin-bottom: 15px;
    }
}


/*#root {*/
    /*background-color: #17181c;*/
    /*color: #f8f8ff*/
/*}*/



@media only screen and (max-width: 1200px) {
    #generateGraphicOuter{
        display: none
    }
}


#generateGraphicSlider {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: -10px
}

#generateGraphicButton {
    margin-top: 15px;
}

.slider {
    margin-bottom: 15px;
}

.padForMenu {
    margin-top: -10px;
    margin-left: 60px;
    padding: 10px;
}

i.faicon {
    margin: 10px;
    font-size: 28px;
}

.headerLabel {
    fill-opacity: .4;
}

.headerTextBox {
    font: bold 80px sans-serif;
    stroke: white;
    stroke-width: 5px;
    stroke-opacity: 1;
}


@media only screen and (min-width: 900px) {
    .headerTextBox {
        font: bold 80px sans-serif;
        stroke: white;
        stroke-width: 5px;
        stroke-opacity: 1;
        transform: translate(0px,20px);
    }

    .headerText {
        font: bold 80px sans-serif;
        transform: translate(0px,20px);
    }
}


@media only screen and (max-width: 900px) {
    .headerTextBox {
        font: bold 30px sans-serif;
        stroke: white;
        stroke-width: 5px;
        stroke-opacity: 1;
    }
    .headerText {
        font: bold 30px sans-serif;
    }
}

.hrLarge {
    height: 2px;
    border: none;
    background-color: #999;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -10px;
}

.padForGrid {
    margin: 1%;
    /*margin: 0;*/
}

.centered {
    padding: 10px;
    height: 100px;
    margin: auto;
}

.profilePic {
    padding: 10px;
    margin: auto;
}

p.bigText {
    font-size: 25px;
    margin: 10px;
}

ul {
    font-size: 15px;
}

@media only screen and (max-width: 600px) {
    .card {
        margin-left: -25px;
        margin-right: -25px;
    }
    .well {
        margin-left: -5px;
        margin-right: -5px;
    }
}

.list-group-item:hover {
    cursor: pointer;
    /*background-color: #ffa300 !important;*/
}

.list-group-item {
    border-radius: 5px;
    box-shadow: 0px 1px 1px #00000055;
    margin: 2px;
}

/*professional interests selected styles*/
html body div#root div div.container-fluid div.well div.padForGrid.row div.col-md-6.col-xs-12 div.card div.content div.profInterests button.list-group-item.active {
    background-color: #bbbbeb  !important;
    border-color: #bbbbeb !important;
    color: black !important;
    background-image: none !important;
}

/*technical stack selected styles*/
html body div#root div div.container-fluid div.well div.padForGrid.row div.col-md-6.col-xs-12 div.card div.content div button.list-group-item.active {
    background-color: #bbebbb  !important;
    border-color: #bbebbb !important;
    color: black !important;
    background-image: none !important;

}

.dropdownIcon {
    float: right;
    margin-right: 15px;
    font-size: 18px;
}

.expandedListText {
    margin: 10px;
}


div.soopHeader {
    font-size: 18px;
    font-weight: bold;
}

span.soopHeader {
    font-size: 18px;
    margin-left: 5px;
    font-weight: normal;
}

.soopHeaderTitle {
    margin-bottom: 15px;
}

.list-group-item.active div.col-xs-8 div.soopHeader span.soopHeader {
    color: white;
}

#dimOverlay.active {
    transition-property: background, visibility;
    transition-duration: 1s, 1s;
    transition-delay: .8s, .8s;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    visibility: visible;
    background: rgba(0,0,0,0.8);
}

#dimOverlay {
    transition-property: background, visibility;
    transition-duration: 1s, 1s;
    transition-delay: 0s, 0s;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.0);
    visibility: hidden
}



.hoveringOnTopOfDimmer {
    z-index:2;
}

.soopButtonCol {
    margin-top: 5px;
    margin-bottom: 5px;
}
.soopList {
    margin-bottom: 75px;
}

.list-group-item {
    cursor: pointer;
}

.padText {
    padding-left: 10px;
}

div.react-photo-gallery--gallery div img {
    border-radius: 4px;
}

.modal-backdrop.in {
    opacity: .85 !important;
}

button.aws-btn {
    margin: 5px;
}

.centerJustify {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: -30px;
}

.centerJustifyNoMargin {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
}



@media only screen and (min-width: 700px) {
.resumePdf {
    display: flex;
    align-items: center;
    justify-content: center;
}
}

@media only screen and (max-width: 700px) {
.resumePdf {
    align-items: center;
    justify-content: center;
}
}
.react-pdf__Page__canvas {
    width: 100% !important;
    min-width: 570px !important;
    overflow: scroll;
    border: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.switch{border:1px solid #ccc;width:50px;height:26px;border-radius:13px;cursor:pointer;display:inline-block}.switch-toggle{border:1px solid #999;box-shadow:1px 1px 1px #ccc;width:25px;height:24px;left:0;border-radius:12px;background:#fff;position:relative;transition:left .2s ease-in-out}.switch.on{background:green}.switch.on .switch-toggle{left:23px}.switch.disabled{cursor:not-allowed}
.aws-btn {
  --button-default-height: 44px;
  --button-default-font-size: 14px;
  --button-default-line-height: 24px;
  --button-default-border-radius: 2px;
  --button-default-placeholder-width: 120px;
  --button-icon-width: 44px;
  --button-icon-height: 44px;
  --button-small-width: 88px;
  --button-small-height: 44px;
  --button-medium-width: 136px;
  --button-medium-height: 44px;
  --button-large-width: 192px;
  --button-large-height: 44px;
  --button-font-family: inherit;
  --button-font-color: #FFFFFF;
  --button-font-weight: 600;
  --button-font-style: normal;
  --button-hover-darken-opacity: 0.1;
  --button-letter-spacing: 0px;
  --button-horizontal-padding: 16px;
  --button-raise-level: 2px;
  --button-hover-pressure: 1;
  --loading-transition-speed: 6s;
  --transform-speed: 0.15s;
  --button-primary-color: #1E88E5;
  --button-primary-color-dark: #1360a4;
  --button-primary-color-light: white;
  --button-primary-color-hover: #187bd1;
  --button-primary-color-active: #166dba;
  --button-primary-border: none;
  --button-secondary-color: #f2f6f9;
  --button-secondary-color-dark: #1360a4;
  --button-secondary-color-light: #1E88E5;
  --button-secondary-color-hover: #e1eaf1;
  --button-secondary-color-active: #cfdee9;
  --button-secondary-border: 2px solid #1E88E5;
  --button-anchor-color: #0e4f88;
  --button-anchor-color-dark: #072743;
  --button-anchor-color-light: white;
  --button-anchor-color-hover: #0d4a7f;
  --button-anchor-color-active: #0c4271;
  --button-anchor-border: none;
  --button-disabled-color: #AFAFAF;
  --button-disabled-color-dark: #898989;
  --button-disabled-color-light: #969696;
  --button-disabled-color-hover: #AFAFAF;
  --button-disabled-color-active: #AFAFAF;
  --button-disabled-border: none;
  --button-placeholder-color: #AFAFAF;
  --button-placeholder-color-dark: #898989;
  --button-placeholder-color-light: #969696;
  --button-placeholder-color-hover: #AFAFAF;
  --button-placeholder-color-active: #AFAFAF;
  --button-placeholder-border: none;
  --button-mail-color: #cfcfcf;
  --button-mail-color-dark: #9c9c9c;
  --button-facebook-color: #eb9600;
  --button-facebook-color-dark: #b17100;
  --button-messenger-color: #3186f6;
  --button-messenger-color-dark: #0960d1;
  --button-twitter-color: #00aced;
  --button-twitter-color-dark: #0074a1;
  --button-linkedin-color: #0077b5;
  --button-linkedin-color-dark: #004569;
  --button-whatsapp-color: #25d366;
  --button-whatsapp-color-dark: #1a9247;
  --button-github-color: #25292e;
  --button-github-color-dark: #030304;
  --button-reddit-color: #fc461e;
  --button-reddit-color-dark: #cb2703;
  --button-pinterest-color: #bd091c;
  --button-pinterest-color-dark: #740611;
  --button-gplus-color: #ce5b4e;
  --button-gplus-color-dark: #a3392d;
  --button-youtube-color: #337dff;
  --button-youtube-color-dark: #2357b2;
  --button-instagram-radial-color: radial-gradient(circle at 25% 110%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  --button-instagram-radial-color-dark: radial-gradient(circle at 25% 110%, #b9a800 0%, #938500 5%, #b10f00 45%, #8c0061 60%, #002ca9 90%); }

.aws-btn {

    --button-default-height: 48px;
    --button-default-font-size: 14px;
    --button-default-border-radius: 6px;
    --button-horizontal-padding: 20px;
    --button-raise-level: 5px;
    --button-hover-pressure: 1;
    --transform-speed: .185s;
    --button-primary-color: #00ad57;
    --button-primary-color-dark: #007a3d;
    --button-primary-color-light: #ffffff;
    --button-primary-color-hover: #007a3d;
    --button-primary-color-active: #2a3143;
    --button-primary-border: none;
    --button-secondary-color: #6b6bdf;
    --button-secondary-color-dark: #4a4a9c;
    --button-secondary-color-light: #ffffff;
    --button-secondary-color-hover: #4a4a9c;
    --button-secondary-border: none;
    --button-anchor-color: #f3c8ad;
    --button-anchor-color-dark: #734922;
    --button-anchor-color-light: #4c3016;
    --button-anchor-color-hover: #f1bfa0;
    --button-anchor-border: 1px solid #8c633c;

}

/**
  button-color:
    color-name, (required)
    background, (required)
    darker-background, (required)
    font-color, (required)
    hover-background,
    border,
*/
/**
   button-social-color:
     color-name,
     background,
     darken-background,
     font-color
  */
/**
 button-size: size-name, width, height, font-size, line-height
*/
.aws-btn .aws-btn__wrapper:before, .aws-btn .aws-btn__wrapper:after, .aws-btn .aws-btn__content:after, .aws-btn--progress .aws-btn__progress:before, .aws-btn--progress .aws-btn__progress:after, .aws-btn--progress .aws-btn__content:after {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.aws-btn {
  padding: 0;
  margin: 0; }

.aws-btn, .aws-btn:focus {
  outline-color: 0;
  outline-style: none;
  outline-width: 0; }

.aws-btn {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

button.aws-btn .aws-btn__wrapper {
  margin-top: calc(var(--button-raise-level) * -1); }

.aws-btn {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  height: var(--button-default-height);
  position: relative;
  background-color: transparent;
  font-size: var(--button-default-font-size);
  line-height: var(--button-default-line-height);
  font-weight: var(--button-font-weight);
  font-family: var(--button-font-family);
  font-style: var(--button-font-style);
  letter-spacing: var(--button-letter-spacing);
  text-rendering: auto;
  text-decoration: none;
  text-align: center;
  transition: opacity .1s ease-out;
  border: none;
  opacity: 0;
  cursor: pointer;
  -webkit-font-smoothing: antialiased; }
  .aws-btn .aws-btn__wrapper {
    position: relative;
    font-family: var(--button-font-family);
    display: flex;
    align-items: stretch;
    width: 100%;
    height: calc(100% - var(--button-raise-level)); }
    .aws-btn .aws-btn__wrapper:before {
      content: " ";
      border-radius: var(--button-default-border-radius);
      top: auto;
      bottom: calc(var(--button-raise-level) * -1);
      z-index: 1;
      transition: transform var(--transform-speed) ease-out, background var(--transform-speed) ease-out; }
    .aws-btn .aws-btn__wrapper:after {
      content: " ";
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: var(--button-default-border-radius);
      z-index: 3;
      width: 0;
      top: var(--button-raise-level); }
  .aws-btn .aws-btn__content {
    position: relative;
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    border-radius: var(--button-default-border-radius);
    text-indent: 0;
    z-index: 3;
    overflow: hidden;
    padding: 0 var(--button-horizontal-padding);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform-style: flat;
    transform: skew(0) translate3d(0, 0, 0);
    transition: border var(--transform-speed) ease-out, transform var(--transform-speed) ease-out, background var(--transform-speed) ease-out, color var(--transform-speed) ease-out; }
    .aws-btn .aws-btn__content > span:nth-child(1) {
      display: block; }
      .aws-btn .aws-btn__content > span:nth-child(1) > svg {
        vertical-align: text-top;
        margin-top: -2px; }
  .aws-btn:before {
    content: " ";
    background-color: rgba(0, 0, 0, 0.3);
    width: calc(100% - 2px);
    height: calc(100% - (var(--button-raise-level) * 2));
    bottom: calc(0px - (var(--button-raise-level) / 2));
    left: 1px;
    position: absolute;
    border-radius: var(--button-default-border-radius);
    z-index: 1;
    transform: skewY(0) translate3d(0, 0, 0);
    transition: transform calc(var(--transform-speed) * 0.8) ease-out, background calc(var(--transform-speed) * 0.8) ease-out; }
  .aws-btn--primary .aws-btn__wrapper:before {
    background: var(--button-primary-color-dark); }
  .aws-btn--primary .aws-btn__content {
    background: var(--button-primary-color);
    color: var(--button-primary-color-light);
    border: var(--button-primary-border); }
  .aws-btn--primary .aws-btn__wrapper:hover .aws-btn__content {
    background: var(--button-primary-color-hover); }
  .aws-btn--primary.aws-btn--active .aws-btn__wrapper .aws-btn__content {
    background: var(--button-primary-color-active); }
  .aws-btn--primary.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--primary.aws-btn--progress .aws-btn__progress:before, .aws-btn--primary.aws-btn--progress .aws-btn__progress:after {
    color: var(--button-primary-color-light); }
  .aws-btn--secondary .aws-btn__wrapper:before {
    background: var(--button-secondary-color-dark); }
  .aws-btn--secondary .aws-btn__content {
    background: var(--button-secondary-color);
    color: var(--button-secondary-color-light);
    border: var(--button-secondary-border); }
  .aws-btn--secondary .aws-btn__wrapper:hover .aws-btn__content {
    background: var(--button-secondary-color-hover); }
  .aws-btn--secondary.aws-btn--active .aws-btn__wrapper .aws-btn__content {
    background: var(--button-secondary-color-active); }
  .aws-btn--secondary.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--secondary.aws-btn--progress .aws-btn__progress:before, .aws-btn--secondary.aws-btn--progress .aws-btn__progress:after {
    color: var(--button-secondary-color-light); }
  .aws-btn--link .aws-btn__wrapper:before {
    background: var(--button-anchor-color-dark); }
  .aws-btn--link .aws-btn__content {
    background: var(--button-anchor-color);
    color: var(--button-anchor-color-light);
    border: var(--button-anchor-border); }
  .aws-btn--link .aws-btn__wrapper:hover .aws-btn__content {
    background: var(--button-anchor-color-hover); }
  .aws-btn--link.aws-btn--active .aws-btn__wrapper .aws-btn__content {
    background: var(--button-anchor-color-active); }
  .aws-btn--link.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--link.aws-btn--progress .aws-btn__progress:before, .aws-btn--link.aws-btn--progress .aws-btn__progress:after {
    color: var(--button-anchor-color-light); }
  .aws-btn--disabled .aws-btn__wrapper:before {
    background: var(--button-disabled-color-dark); }
  .aws-btn--disabled .aws-btn__content {
    background: var(--button-disabled-color);
    color: var(--button-disabled-color-light);
    border: var(--button-disabled-border); }
  .aws-btn--disabled .aws-btn__wrapper:hover .aws-btn__content {
    background: var(--button-disabled-color-hover); }
  .aws-btn--disabled.aws-btn--active .aws-btn__wrapper .aws-btn__content {
    background: var(--button-disabled-color-active); }
  .aws-btn--disabled.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--disabled.aws-btn--progress .aws-btn__progress:before, .aws-btn--disabled.aws-btn--progress .aws-btn__progress:after {
    color: var(--button-disabled-color-light); }
  .aws-btn--placeholder .aws-btn__wrapper:before {
    background: var(--button-placeholder-color-dark); }
  .aws-btn--placeholder .aws-btn__content {
    background: var(--button-placeholder-color);
    color: var(--button-placeholder-color-light);
    border: var(--button-placeholder-border); }
  .aws-btn--placeholder .aws-btn__wrapper:hover .aws-btn__content {
    background: var(--button-placeholder-color-hover); }
  .aws-btn--placeholder.aws-btn--active .aws-btn__wrapper .aws-btn__content {
    background: var(--button-placeholder-color-active); }
  .aws-btn--placeholder.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--placeholder.aws-btn--progress .aws-btn__progress:before, .aws-btn--placeholder.aws-btn--progress .aws-btn__progress:after {
    color: var(--button-placeholder-color-light); }
  .aws-btn--facebook .aws-btn__wrapper:before {
    background: #b17100; }
  .aws-btn--facebook .aws-btn__content {
    background: #eb9600;
    color: #FFFFFF; }
  .aws-btn--facebook.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--facebook.aws-btn--progress .aws-btn__progress:before, .aws-btn--facebook.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--messenger .aws-btn__wrapper:before {
    background: #0960d1; }
  .aws-btn--messenger .aws-btn__content {
    background: #3186f6;
    color: #FFFFFF; }
  .aws-btn--messenger.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--messenger.aws-btn--progress .aws-btn__progress:before, .aws-btn--messenger.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--twitter .aws-btn__wrapper:before {
    background: #0074a1; }
  .aws-btn--twitter .aws-btn__content {
    background: #00aced;
    color: #FFFFFF; }
  .aws-btn--twitter.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--twitter.aws-btn--progress .aws-btn__progress:before, .aws-btn--twitter.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--linkedin .aws-btn__wrapper:before {
    background: #004569; }
  .aws-btn--linkedin .aws-btn__content {
    background: #0077b5;
    color: #FFFFFF; }
  .aws-btn--linkedin.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--linkedin.aws-btn--progress .aws-btn__progress:before, .aws-btn--linkedin.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--whatsapp .aws-btn__wrapper:before {
    background: #1a9247; }
  .aws-btn--whatsapp .aws-btn__content {
    background: #25d366;
    color: #FFFFFF; }
  .aws-btn--whatsapp.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--whatsapp.aws-btn--progress .aws-btn__progress:before, .aws-btn--whatsapp.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--github .aws-btn__wrapper:before {
    background: #030304; }
  .aws-btn--github .aws-btn__content {
    background: #25292e;
    color: #FFFFFF; }
  .aws-btn--github.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--github.aws-btn--progress .aws-btn__progress:before, .aws-btn--github.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--reddit .aws-btn__wrapper:before {
    background: #cb2703; }
  .aws-btn--reddit .aws-btn__content {
    background: #fc461e;
    color: #FFFFFF; }
  .aws-btn--reddit.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--reddit.aws-btn--progress .aws-btn__progress:before, .aws-btn--reddit.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--pinterest .aws-btn__wrapper:before {
    background: #740611; }
  .aws-btn--pinterest .aws-btn__content {
    background: #bd091c;
    color: #FFFFFF; }
  .aws-btn--pinterest.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--pinterest.aws-btn--progress .aws-btn__progress:before, .aws-btn--pinterest.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--gplus .aws-btn__wrapper:before {
    background: #a3392d; }
  .aws-btn--gplus .aws-btn__content {
    background: #ce5b4e;
    color: #FFFFFF; }
  .aws-btn--gplus.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--gplus.aws-btn--progress .aws-btn__progress:before, .aws-btn--gplus.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--youtube .aws-btn__wrapper:before {
    background: #2357b2; }
  .aws-btn--youtube .aws-btn__content {
    background: #337dff;
    color: #FFFFFF; }
  .aws-btn--youtube.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--youtube.aws-btn--progress .aws-btn__progress:before, .aws-btn--youtube.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--mail .aws-btn__wrapper:before {
    background: #9c9c9c; }
  .aws-btn--mail .aws-btn__content {
    background: #cfcfcf;
    color: #FFFFFF; }
  .aws-btn--mail.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--mail.aws-btn--progress .aws-btn__progress:before, .aws-btn--mail.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--instagram .aws-btn__wrapper:before {
    background: radial-gradient(circle at 25% 110%, #b9a800 0%, #938500 5%, #b10f00 45%, #8c0061 60%, #002ca9 90%); }
  .aws-btn--instagram .aws-btn__content {
    background: radial-gradient(circle at 25% 110%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    color: #FFFFFF; }
  .aws-btn--instagram.aws-btn--progress .aws-btn__content > span {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center; }
  .aws-btn--instagram.aws-btn--progress .aws-btn__progress:before, .aws-btn--instagram.aws-btn--progress .aws-btn__progress:after {
    color: #FFFFFF; }
  .aws-btn--placeholder {
    width: var(--button-default-placeholder-width); }
    .aws-btn--placeholder .aws-btn__content > span {
      display: block;
      width: 100%;
      height: 40%;
      background-color: var(--button-placeholder-color-light);
      border-radius: 2px;
      position: relative;
      overflow: hidden; }
      .aws-btn--placeholder .aws-btn__content > span:before {
        content: '';
        background-color: rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-animation: placeholder calc(var(--transform-speed) * 14) linear forwards infinite;
                animation: placeholder calc(var(--transform-speed) * 14) linear forwards infinite; }
  .aws-btn--visible {
    opacity: 1; }
  .aws-btn--left:before {
    transform: skewY(calc(1deg * var(--button-hover-pressure) * 1)) translate3d(0, calc(-1px * var(--button-hover-pressure) / 2), 0); }
  .aws-btn--left .aws-btn__content {
    transform: skewY(calc(1deg * var(--button-hover-pressure) * -1)); }
  .aws-btn--right:before {
    transform: skewY(calc(1deg * var(--button-hover-pressure) * -1)) translate3d(0, calc(-1px * var(--button-hover-pressure) / 2), 0); }
  .aws-btn--right .aws-btn__content {
    transform: skewY(calc(1deg * var(--button-hover-pressure) * 1)); }
  .aws-btn--middle:before {
    transform: translate3d(0, calc(-1px * var(--button-hover-pressure)), 0); }
  .aws-btn--middle .aws-btn__content {
    transform: translate3d(0, calc(1px * var(--button-hover-pressure)), 0); }
  .aws-btn--icon .aws-btn__content > span:nth-child(1) > svg {
    vertical-align: middle; }
  .aws-btn--active:before {
    transform: translate3d(0, calc(var(--button-raise-level) * -1), 0); }
  .aws-btn--active .aws-btn__content {
    transition: transform calc(var(--transform-speed) * 0.8) ease-out, background calc(var(--transform-speed) * 0.8) ease-out, color calc(var(--transform-speed) * 0.8) ease-out;
    transform: translate3d(0, var(--button-raise-level), 0); }
  .aws-btn--off:before {
    transform: translate3d(0, calc(var(--button-raise-level) * -1), 0);
    background-color: rgba(0, 0, 0, 0.05); }
  .aws-btn--off:hover .aws-btn__wrapper:before {
    background-color: #2d2d2d; }
  .aws-btn--off:hover .aws-btn__content {
    background-color: #313131;
    color: #3b3b3b; }
  .aws-btn--off .aws-btn__wrapper:before {
    background-color: #323232; }
  .aws-btn--off .aws-btn__content {
    background-color: #353535;
    color: #424242;
    transform: translate3d(0, var(--button-raise-level), 0); }
  .aws-btn--icon {
    width: var(--button-icon-width);
    height: var(--button-icon-height);
    font-size: calc(var(--button-default-font-size) * 1.5);
    line-height: calc(var(--button-default-line-height) * 1.5); }
  .aws-btn--small {
    width: var(--button-small-width);
    height: var(--button-small-height);
    font-size: var(--button-default-font-size);
    line-height: var(--button-default-line-height); }
  .aws-btn--medium {
    width: var(--button-medium-width);
    height: var(--button-medium-height);
    font-size: var(--button-default-font-size);
    line-height: var(--button-default-line-height); }
  .aws-btn--large {
    width: var(--button-large-width);
    height: var(--button-large-height);
    font-size: var(--button-default-font-size);
    line-height: var(--button-default-line-height); }
  .aws-btn--fill {
    width: 100%; }

span.aws-btn__bubble {
  display: block;
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;
  opacity: 0;
  transform: scale(0.1);
  -webkit-animation: bubble-ping calc(var(--transform-speed) * 3.5) cubic-bezier(0.5, 0, 0.6, 0.4) 0.05s forwards;
          animation: bubble-ping calc(var(--transform-speed) * 3.5) cubic-bezier(0.5, 0, 0.6, 0.4) 0.05s forwards; }

@-webkit-keyframes placeholder {
  0% {
    transform: translateX(-110%); }
  25% {
    transform: translateX(0%); }
  50% {
    transform: translateX(110%); }
  75% {
    transform: translateX(0%); }
  100% {
    transform: translateX(-110%); } }

@keyframes placeholder {
  0% {
    transform: translateX(-110%); }
  25% {
    transform: translateX(0%); }
  50% {
    transform: translateX(110%); }
  75% {
    transform: translateX(0%); }
  100% {
    transform: translateX(-110%); } }

@-webkit-keyframes bounce {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1.6); }
  60% {
    transform: scale(1.4); }
  100% {
    transform: scale(1.5); } }

@keyframes bounce {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1.6); }
  60% {
    transform: scale(1.4); }
  100% {
    transform: scale(1.5); } }

@-webkit-keyframes bubble-ping {
  1% {
    visibility: visible; }
  10% {
    opacity: 0; }
  50% {
    opacity: 1; }
  99% {
    transform: scale(1);
    opacity: 0; }
  100% {
    visibility: hidden; } }

@keyframes bubble-ping {
  1% {
    visibility: visible; }
  10% {
    opacity: 0; }
  50% {
    opacity: 1; }
  99% {
    transform: scale(1);
    opacity: 0; }
  100% {
    visibility: hidden; } }

.aws-btn--progress .aws-btn__progress {
  position: relative;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  text-indent: 0;
  z-index: 3;
  overflow: hidden;
  transition: border 0.15s ease-out, transform 0.15s ease-out, background 0.15s ease-out, color 0.15s ease-out; }
  .aws-btn--progress .aws-btn__progress > span {
    transition: opacity 0.075s ease-out 0.1125s; }
  .aws-btn--progress .aws-btn__progress:before, .aws-btn--progress .aws-btn__progress:after {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.65);
    opacity: 0;
    transition: transform 0.15s ease-out 0.05s, opacity 0.1125s ease-out 0.05s; }
  .aws-btn--progress .aws-btn__progress:before {
    content: attr(data-loading);
    display: none;
    transform: translate3d(0, 50%, 0); }
  .aws-btn--progress .aws-btn__progress:after {
    content: attr(data-status);
    display: none;
    transform: translate3d(0, -50%, 0); }

.aws-btn--progress.aws-btn--active .aws-btn__progress > span {
  opacity: 0.075; }

.aws-btn--progress.aws-btn--active .aws-btn__progress:after,
.aws-btn--progress.aws-btn--active .aws-btn__progress:before,
.aws-btn--progress.aws-btn--active .aws-btn__content:after {
  display: block; }

.aws-btn--progress .aws-btn__content:after {
  content: " ";
  background-color: rgba(0, 0, 0, 0.15);
  transform: translate3d(-100%, 0, 0);
  width: 100%;
  display: none; }

.aws-btn--start .aws-btn__progress:before {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.aws-btn--start .aws-btn__content:after {
  transition: transform 6s ease-out;
  transform: translate3d(-15%, 0, 0); }

.aws-btn--errored .aws-btn__content:after {
  background-color: rgba(255, 0, 0, 0.75); }

.aws-btn--end .aws-btn__content:after {
  transition: transform 0.3s ease-out;
  transform: translate3d(0, 0, 0); }

.aws-btn--end .aws-btn__progress:after {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.aws-btn--end .aws-btn__progress:before {
  transform: translate3d(0, 50%, 0);
  opacity: 0; }


.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb;
}
.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}
@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

/*# sourceMappingURL=rc-slider.css.map*/
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.color-puke {
  color: #9dc03b; }

.ReactTable {
  font-family: sans-serif;
  position: relative;
  /*display: -webkit-box;*/
  /*display: -ms-flexbox;*/
  /*display: flex;*/
  /*-webkit-box-orient: vertical;*/
  /*-webkit-box-direction: normal;*/
  /*-ms-flex-direction: column;*/
  /*flex-direction: column;*/
  /*border: 0px solid rgba(0, 0, 0, 0.1);*/
  /*box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25), inset -4px -4px 4px rgba(0, 0, 0, 0.25);*/
  padding: 15px 10px; }
  .ReactTable * {
    box-sizing: border-box; }
  .ReactTable .rt-table {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto; }
  .ReactTable .rt-thead {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .ReactTable .rt-thead.-headerGroups {
      background: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .ReactTable .rt-thead.-filters {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
      .ReactTable .rt-thead.-filters .rt-th {
        border-right: 1px solid rgba(0, 0, 0, 0.02); }
    .ReactTable .rt-thead .rt-tr {
      text-align: center; }
    .ReactTable .rt-thead .rt-th,
    .ReactTable .rt-thead .rt-td {
      padding: 5px 5px;
      line-height: normal;
      position: relative;
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: inset 0 0 0 0 transparent;
      font-size: 12px;
      font-weight: 500;
      color: #1d1d1e; }
    .ReactTable .rt-thead .rt-th.-sort-asc,
    .ReactTable .rt-thead .rt-td.-sort-asc {
      box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6); }
    .ReactTable .rt-thead .rt-th.-sort-desc,
    .ReactTable .rt-thead .rt-td.-sort-desc {
      box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6); }
    .ReactTable .rt-thead .rt-th.-cursor-pointer,
    .ReactTable .rt-thead .rt-td.-cursor-pointer {
      cursor: pointer; }
    .ReactTable .rt-thead .rt-th:last-child,
    .ReactTable .rt-thead .rt-td:last-child {
      border-right: 0; }
    .ReactTable .rt-thead .rt-resizable-header {
      overflow: visible; }
      .ReactTable .rt-thead .rt-resizable-header:last-child {
        overflow: hidden; }
    .ReactTable .rt-thead .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.1px;
      text-align: left;
      color: #9b9b9b; }
    .ReactTable .rt-thead .rt-header-pivot {
      border-right-color: #f7f7f7; }
      .ReactTable .rt-thead .rt-header-pivot:after, .ReactTable .rt-thead .rt-header-pivot:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }
      .ReactTable .rt-thead .rt-header-pivot:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #fff;
        border-width: 8px;
        margin-top: -8px; }
      .ReactTable .rt-thead .rt-header-pivot:before {
        border-color: rgba(102, 102, 102, 0);
        border-left-color: #f7f7f7;
        border-width: 10px;
        margin-top: -10px; }
  .ReactTable .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto; }
    .ReactTable .rt-tbody .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
      border: 0px solid #99999985;
      margin-top: 3px; }

.ReactTable .rt-tr#inactive:hover {
    /*border: 1px solid #1dc7ea; */
    border: 3px solid #eee; 
    background-color: #eee;
    cursor: pointer;
}

.ReactTable .rt-tr#active:hover {
    /*border: 1px solid #1dc7ea; */
    border: 3px solid #eee; 
    background-color: #eee;
    cursor: pointer;
}

.ReactTable .rt-tr#active {
    /*border: 1px solid #1dc7ea; */
    border: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
}

    .ReactTable .rt-tbody .rt-td {
      border-right: 0px solid rgba(0, 0, 0, 0.02); }
      .ReactTable .rt-tbody .rt-td:last-child {
        border-right: 0; }
    .ReactTable .rt-tbody .rt-expandable {
      cursor: pointer; }
  .ReactTable .rt-tr-group {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.2px;
    text-align: left;
    color: #272728; }
  .ReactTable .rt-tr {
    flex: 1 0 auto;
    display: inline-flex; }
  .ReactTable .rt-th,
  .ReactTable .rt-td {
    flex: 1 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
    font-size: 12px;
    font-weight: 500;
    color: #1d1d1e; }
  .ReactTable .rt-th.-hidden,
  .ReactTable .rt-td.-hidden {
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    opacity: 0 !important; }
  .ReactTable .rt-expander {
    /*display: inline-block;*/
      display: none;
    position: relative;
    margin: 0;
    color: transparent;
    margin: 0 10px; }
    .ReactTable .rt-expander:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      border-left: 5.04px solid transparent;
      border-right: 5.04px solid transparent;
      border-top: 7px solid rgba(0, 0, 0, 0.8);
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      cursor: pointer; }
    .ReactTable .rt-expander.-open:after {
      transform: translate(-50%, -50%) rotate(0); }
  .ReactTable .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    }
  .ReactTable .rt-tfoot {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15); }
    .ReactTable .rt-tfoot .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.05); }
      .ReactTable .rt-tfoot .rt-td:last-child {
        border-right: 0; }
  .ReactTable.-striped .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.03); }
  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.03);
    border-left: 4px solid #1dc7ea;
    padding-left: 0px; }
  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow) {
    padding-left: 4px;
    margin-left: 3px; }
  .ReactTable .-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1); }
    .ReactTable .-pagination input,
    .ReactTable .-pagination select {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: normal;
      outline: none; }
    .ReactTable .-pagination .-btn {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(0, 0, 0, 0.6);
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.1s ease;
      cursor: pointer;
      outline: none; }
      .ReactTable .-pagination .-btn[disabled] {
        opacity: 0.5;
        cursor: default; }
      .ReactTable .-pagination .-btn:not([disabled]):hover {
        background: rgba(0, 0, 0, 0.3);
        color: #fff; }
    .ReactTable .-pagination .-previous,
    .ReactTable .-pagination .-next {
      flex: 1 1;
      text-align: center; }
    .ReactTable .-pagination .-center {
      flex: 1.5 1;
      text-align: center;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around; }
    .ReactTable .-pagination .-pageInfo {
      display: inline-block;
      margin: 3px 10px;
      white-space: nowrap; }
    .ReactTable .-pagination .-pageJump {
      display: inline-block; }
      .ReactTable .-pagination .-pageJump input {
        width: 70px;
        text-align: center; }
    .ReactTable .-pagination .-pageSizeOptions {
      margin: 3px 10px; }
  .ReactTable .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: 80px;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(0, 0, 0, 0.5); }
  .ReactTable .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none; }
    .ReactTable .-loading > div {
      position: absolute;
      display: block;
      text-align: center;
      width: 100%;
      top: 50%;
      left: 0;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      transform: translateY(-52%);
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
    .ReactTable .-loading.-active {
      opacity: 1;
      z-index: 2;
      pointer-events: all; }
      .ReactTable .-loading.-active > div {
        transform: translateY(50%); }
  .ReactTable .rt-resizing .rt-th,
  .ReactTable .rt-resizing .rt-td {
    transition: none !important;
    cursor: col-resize;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.singleActionButton {
  cursor: pointer;
  color: #9dc03b; }

.checkTable .rt-tr .rt-td:not(:first-child) {
  line-height: 22px; }

div.Select-input {
  display: none;
}
input {
    width: 100% !important;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 0px;
}


@media only screen and (max-width: 900px) {
}

.reactTableStyle {
    width: 100%
}

.padSubComponent {
    padding: 3%
}

 div.ReactTable.reactTableStyle.techStack div.rt-table div.rt-tbody div.rt-tr-group div#active {
    border-radius: 4px;
      border: 1px solid #99999985;
    background-color: #bbebbb  !important;
}

 div.ReactTable.reactTableStyle.profInterests div.rt-table div.rt-tbody div.rt-tr-group div#active {
    border-radius: 4px;
      border: 1px solid #99999985;
    background-color: #bbbbeb  !important;
}

 div.ReactTable.reactTableStyle.soopListTable div.rt-table div.rt-tbody div.rt-tr-group div#active {
    border-radius: 4px;
      border: 1px solid #99999985;
    background-color: #bbbbbe  !important;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #337dff;
  color: white !important;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: #007a3d;
  color: #00ad57;
  color: #ffb533;
  color: #ffac1a;
  color: #ffa300 !important;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  /*background: #00ad57;*/
    /*background: #47a607*/
    /*background: #337dff;*/
    background: #6b6bdf;
    /*background: black;*/
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  /*background: #57c5f7;*/
  /*background: #00ad57;*/
    background: #47a607
}

/* General sidebar styles */
.bm-menu {
  /*background: #2a3143;*/
  background: #f8f8ff;
  background: #0762a6;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.85) !important;
}

#sidebarSignature {
    color: white;
    margin-left: 10px;
    margin-top: -20px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}


.card {
  border-radius: 4px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.10), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #FFFFFF;
  margin-bottom: 30px; }


  .card .image {
    width: 100%;
    overflow: hidden;
    height: 260px;
    border-radius: 4px 4px 0 0;
    position: relative;
    transform-style: preserve-3d; }
    .card .image img {
      width: 100%; }
  .card .filter {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.68);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=0); }
    .card .filter .btn {
      position: relative;
      top: 50%;
      transform: translateY(-50%); }
  .card:hover .filter {
    opacity: 1;
    filter: alpha(opacity=100); }
  .card .btn-hover {
    opacity: 0;
    filter: alpha(opacity=0); }
  .card:hover .btn-hover {
    opacity: 1;
    filter: alpha(opacity=100); }
  .card .content {
      margin: 5px;
    padding: 1px 1px 1px 1px; }
  .card .header {
    padding: 15px 15px 0; }
  .card .category,
  .card label {
    font-size: 14px;
    font-weight: 400;
    /*color: #9A9A9A;*/
    color: black;
    margin-bottom: 0px; }
    .card .category i,
    .card label i {
      font-size: 16px; }
  .card label {
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase; }
  .card .title {
    margin: 0;
    /*color: #333333;*/
    color: black;
    font-weight: 300; }
  .card .avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px; }
  .card .description {
    font-size: 14px;
    color: #333; }
  .card .footer {
    padding: 0;
    background-color: transparent;
    line-height: 30px; }
    .card .footer .legend {
      padding: 5px 0; }
    .card .footer hr {
      margin-top: 5px;
      margin-bottom: 5px; }
  .card .stats {
    color: #a9a9a9; }
  .card .footer div {
    display: inline-block; }
  .card .author {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase; }
  .card .author i {
    font-size: 14px; }
  .card h6 {
    font-size: 12px;
    margin: 0; }
  .card.card-separator:after {
    height: 100%;
    right: -15px;
    top: 0;
    width: 1px;
    background-color: #DDDDDD;
    content: "";
    position: absolute; }
  .card .ct-chart {
    margin: 30px 0 30px;
    height: 245px; }
  .card .table tbody td:first-child,
  .card .table thead th:first-child {
    padding-left: 15px; }
  .card .table tbody td:last-child,
  .card .table thead th:last-child {
    padding-right: 15px; }
  .card .alert {
    border-radius: 4px;
    position: relative; }
    .card .alert.alert-with-icon {
      padding-left: 65px; }

.card-user .image {
  height: 110px; }

.card-user .image-plain {
  height: 0;
  margin-top: 110px; }

.card-user .author {
  text-align: center;
  text-transform: none;
  margin-top: -70px; }

.card-user .avatar {
  width: 124px;
  height: 124px;
  border: 5px solid #FFFFFF;
  position: relative;
  margin-bottom: 15px; }
  .card-user .avatar.border-gray {
    border-color: #EEEEEE; }

.card-user .title {
  line-height: 24px; }

.card-user .content {
  min-height: 240px; }

.card-user .footer,
.card-price .footer {
  padding: 5px 15px 10px; }

.card-user hr,
.card-price hr {
  margin: 5px 15px; }

.card-plain {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0; }
  .card-plain .image {
    border-radius: 4px; }

.card-stats .icon-big {
  font-size: 3em;
  min-height: 64px; }
  .card-stats .icon-big i {
    font-weight: bold;
    line-height: 59px; }

.card-stats .numbers {
  font-size: 2em;
  text-align: right; }
  .card-stats .numbers p {
    margin: 0; }






/*.card.link:hover {*/
  /*background-color: #262626;*/
  /*opacity: .6;*/
/*}*/
  /*.card.link:hover .category {*/
    /*font-size: 14px;*/
    /*font-weight: 400;*/
    /*[>color: #9A9A9A;<]*/
    /*color: white !important;*/
    /*margin-bottom: 0px; }*/

  /*.card.link:hover .title {*/
    /*margin: 0;*/
    /*[>color: #333333;<]*/
    /*color: white !important;*/
    /*font-weight: 300; }*/


@media only screen and (min-width: 900px) {
#slideMenu {
    display: none
}

/*.blueStar {*/
    /*fill: #ffffff;*/
    /*fill-opacity: 0;*/
    /*stroke: #337dff;*/
    /*stroke-width: 5*/
/*}*/

.metismenu {
  position: fixed;
  width: var(--side-menu-width);
  padding: 20px;
  padding-top: 165px;
  bottom: 0;
  left: 0;
  top: 0;
  /*background-color: #5b3d7c;*/
  background-color: #0762a6;
}


.metismenuGridPad {
    margin-left: var(--side-menu-width)
}

.metisRoute {
    font-size: 1.15em
}

#metisSignature {
  z-index: 3;
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  height: 100px;
  padding: 25px;
  color: white;
}

#metisContact {
  z-index: 3;
  position: fixed;
  bottom: 0px;
  left: 0px;
  top: 80px;
  width: 225px;
  height: 100px;
  padding-top: 25px;
  padding-left: 15px;
}

#sidebarSignature {
    color: white;
}

div#sidebarSignature hr{
    margin-top: -5px;
}

li.metismenu-item {
    /*padding-top: 10px;*/
}
html body div#root div div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item a.metismenu-link.has-active-child {
    color: white;
    background-color: #07a64b;
    border-radius: 4px;
}

html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible li.metismenu-item a.metismenu-link.has-active-child {
    color: white;
    background-color: #07a64b;
    border-radius: 4px;
}
html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item a.metismenu-link.active {
    background-color: #ff3333;
    background-color: #a64b07;
    background-color: #07a69b;
    background-color: #07a64b;
    border-radius: 4px;
    /*padding-top: 8px;*/
    /*padding-left: 5px;*/
}
html body div#root div div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible {
 box-shadow: 0px 8px 8px 2px #0754a6;
 border-radius: 4px;
}
html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible {
 box-shadow: 0px 8px 8px 2px #0754a6;
 border-radius: 4px;
}

html body div#root div div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible li.metismenu-item a.metismenu-link.active {
 box-shadow: 0px 4px 8px 0px #145dab;
 border-radius: 4px;
}
html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible li.metismenu-item a.metismenu-link {
    padding-left: 40px;
}
html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item a.metismenu-link div {
    padding-top: 8px;
}

div.metismenu {
    overflow: auto
}
}


@media only screen and (max-width: 900px) {
#metisMenu{
    display: none
}

/*.blueStar {*/
    /*fill: #ffffff;*/
    /*fill-opacity: 0;*/
    /*stroke: #763d7c;*/
    /*stroke-width: 5*/
/*}*/
}


