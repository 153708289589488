div.d3svg {
    overflow: hidden
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pumpkin {
    fill: yellow;
    stroke: orange;
    stroke-width: 5;
}

.greenStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #47a607;
    /*stroke: #337dff;*/
    stroke-width: 5
}

.purpleStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #763d7c;
    stroke-width: 5
}

.yellowStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #ffa300;
    stroke-width: 5
}

.blueStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #337dff;
    stroke-width: 5
}

.redStar {
    fill: #ffffff;
    fill-opacity: 0;
    stroke: #ff005c;
    stroke-width: 5
}

g.clickable circle.greenStar:hover {
    fill: red;
    stroke: #a60747;
    cursor: pointer;
}

g.clickable circle.redStar:hover {
    fill: red;
    stroke: #a60747;
    cursor: pointer;
}

g.clickable circle.blueStar:hover {
    fill: red;
    stroke: #a60747;
    cursor: pointer;
}

g.clickable circle.yellowStar:hover {
    fill: red;
    stroke: #a60747;
    cursor: pointer;
}

.settingSun {
    fill: red;
    stroke: purple;
    stroke-width: 10;
    transtion: fill 800ms
}

.greeting {
  font-family: sans-serif;
  margin-left: 25px;
  margin-right: 25px;
}

@media only screen and (min-width: 767px) {
.signature {
    margin-top: -5px;
    user-select: none;
}
}

@media only screen and (max-width: 767px) {
.signature {
    margin-top: -25px;
    user-select: none;
}
}



.name {
    margin-left: -10px;
}

.title {
    margin-top: -17px;
}


div.switch {
    margin-top: -10px;
}

@media only screen and (min-width: 767px) {
    .animation.toggle {
        margin-top: -15px;
        float: right;
    }
}

@media only screen and (max-width: 767px) {
    .animation.toggle {
        margin-top: -180px;
        float: right;
        display: none;
    }
}

@media only screen and (min-width: 767px) {
    #enterSite {
        float: left;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 767px) {
    #enterSite {
        float: left;
        margin-top: -5px;
        margin-left: -18px;
        margin-bottom: 15px;
    }
}


/*#root {*/
    /*background-color: #17181c;*/
    /*color: #f8f8ff*/
/*}*/



@media only screen and (max-width: 1200px) {
    #generateGraphicOuter{
        display: none
    }
}


#generateGraphicSlider {
    user-select: none;
    margin-top: -10px
}

#generateGraphicButton {
    margin-top: 15px;
}

.slider {
    margin-bottom: 15px;
}

.padForMenu {
    margin-top: -10px;
    margin-left: 60px;
    padding: 10px;
}

i.faicon {
    margin: 10px;
    font-size: 28px;
}

.headerLabel {
    fill-opacity: .4;
}

.headerTextBox {
    font: bold 80px sans-serif;
    stroke: white;
    stroke-width: 5px;
    stroke-opacity: 1;
}


@media only screen and (min-width: 900px) {
    .headerTextBox {
        font: bold 80px sans-serif;
        stroke: white;
        stroke-width: 5px;
        stroke-opacity: 1;
        transform: translate(0px,20px);
    }

    .headerText {
        font: bold 80px sans-serif;
        transform: translate(0px,20px);
    }
}


@media only screen and (max-width: 900px) {
    .headerTextBox {
        font: bold 30px sans-serif;
        stroke: white;
        stroke-width: 5px;
        stroke-opacity: 1;
    }
    .headerText {
        font: bold 30px sans-serif;
    }
}

.hrLarge {
    height: 2px;
    border: none;
    background-color: #999;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -10px;
}

.padForGrid {
    margin: 1%;
    /*margin: 0;*/
}

.centered {
    padding: 10px;
    height: 100px;
    margin: auto;
}

.profilePic {
    padding: 10px;
    margin: auto;
}

p.bigText {
    font-size: 25px;
    margin: 10px;
}

ul {
    font-size: 15px;
}

@media only screen and (max-width: 600px) {
    .card {
        margin-left: -25px;
        margin-right: -25px;
    }
    .well {
        margin-left: -5px;
        margin-right: -5px;
    }
}

.list-group-item:hover {
    cursor: pointer;
    /*background-color: #ffa300 !important;*/
}

.list-group-item {
    border-radius: 5px;
    box-shadow: 0px 1px 1px #00000055;
    margin: 2px;
}

/*professional interests selected styles*/
html body div#root div div.container-fluid div.well div.padForGrid.row div.col-md-6.col-xs-12 div.card div.content div.profInterests button.list-group-item.active {
    background-color: #bbbbeb  !important;
    border-color: #bbbbeb !important;
    color: black !important;
    background-image: none !important;
}

/*technical stack selected styles*/
html body div#root div div.container-fluid div.well div.padForGrid.row div.col-md-6.col-xs-12 div.card div.content div button.list-group-item.active {
    background-color: #bbebbb  !important;
    border-color: #bbebbb !important;
    color: black !important;
    background-image: none !important;

}

.dropdownIcon {
    float: right;
    margin-right: 15px;
    font-size: 18px;
}

.expandedListText {
    margin: 10px;
}


div.soopHeader {
    font-size: 18px;
    font-weight: bold;
}

span.soopHeader {
    font-size: 18px;
    margin-left: 5px;
    font-weight: normal;
}

.soopHeaderTitle {
    margin-bottom: 15px;
}

.list-group-item.active div.col-xs-8 div.soopHeader span.soopHeader {
    color: white;
}

#dimOverlay.active {
    transition-property: background, visibility;
    transition-duration: 1s, 1s;
    transition-delay: .8s, .8s;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    visibility: visible;
    background: rgba(0,0,0,0.8);
}

#dimOverlay {
    transition-property: background, visibility;
    transition-duration: 1s, 1s;
    transition-delay: 0s, 0s;
    width: 100%;
    height: 100%;
    z-index: 1;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.0);
    visibility: hidden
}



.hoveringOnTopOfDimmer {
    z-index:2;
}

.soopButtonCol {
    margin-top: 5px;
    margin-bottom: 5px;
}
.soopList {
    margin-bottom: 75px;
}

.list-group-item {
    cursor: pointer;
}

.padText {
    padding-left: 10px;
}

div.react-photo-gallery--gallery div img {
    border-radius: 4px;
}

.modal-backdrop.in {
    opacity: .85 !important;
}

button.aws-btn {
    margin: 5px;
}

.centerJustify {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: -30px;
}

.centerJustifyNoMargin {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
}



@media only screen and (min-width: 700px) {
.resumePdf {
    display: flex;
    align-items: center;
    justify-content: center;
}
}

@media only screen and (max-width: 700px) {
.resumePdf {
    align-items: center;
    justify-content: center;
}
}
.react-pdf__Page__canvas {
    width: 100% !important;
    min-width: 570px !important;
    overflow: scroll;
    border: 10px;
}
