
@media only screen and (min-width: 900px) {
#slideMenu {
    display: none
}

/*.blueStar {*/
    /*fill: #ffffff;*/
    /*fill-opacity: 0;*/
    /*stroke: #337dff;*/
    /*stroke-width: 5*/
/*}*/

.metismenu {
  position: fixed;
  width: var(--side-menu-width);
  padding: 20px;
  padding-top: 165px;
  bottom: 0;
  left: 0;
  top: 0;
  /*background-color: #5b3d7c;*/
  background-color: #0762a6;
}


.metismenuGridPad {
    margin-left: var(--side-menu-width)
}

.metisRoute {
    font-size: 1.15em
}

#metisSignature {
  z-index: 3;
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  height: 100px;
  padding: 25px;
  color: white;
}

#metisContact {
  z-index: 3;
  position: fixed;
  bottom: 0px;
  left: 0px;
  top: 80px;
  width: 225px;
  height: 100px;
  padding-top: 25px;
  padding-left: 15px;
}

#sidebarSignature {
    color: white;
}

div#sidebarSignature hr{
    margin-top: -5px;
}

li.metismenu-item {
    /*padding-top: 10px;*/
}
html body div#root div div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item a.metismenu-link.has-active-child {
    color: white;
    background-color: #07a64b;
    border-radius: 4px;
}

html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible li.metismenu-item a.metismenu-link.has-active-child {
    color: white;
    background-color: #07a64b;
    border-radius: 4px;
}
html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item a.metismenu-link.active {
    background-color: #ff3333;
    background-color: #a64b07;
    background-color: #07a69b;
    background-color: #07a64b;
    border-radius: 4px;
    /*padding-top: 8px;*/
    /*padding-left: 5px;*/
}
html body div#root div div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible {
 box-shadow: 0px 8px 8px 2px #0754a6;
 border-radius: 4px;
}
html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible {
 box-shadow: 0px 8px 8px 2px #0754a6;
 border-radius: 4px;
}

html body div#root div div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible li.metismenu-item a.metismenu-link.active {
 box-shadow: 0px 4px 8px 0px #145dab;
 border-radius: 4px;
}
html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item ul.metismenu-container.visible li.metismenu-item a.metismenu-link {
    padding-left: 40px;
}
html body div#root div div#metisMenu div.metismenu ul.metismenu-container li.metismenu-item a.metismenu-link div {
    padding-top: 8px;
}

div.metismenu {
    overflow: auto
}
}


@media only screen and (max-width: 900px) {
#metisMenu{
    display: none
}

/*.blueStar {*/
    /*fill: #ffffff;*/
    /*fill-opacity: 0;*/
    /*stroke: #763d7c;*/
    /*stroke-width: 5*/
/*}*/
}

